<template>
<div>
    <div class="col-12 px-0"  v-if="loader == true">
       <div class="lds-dual-ring-registry" id="loading"></div>
    </div>

    <div class="registry-sign-up-form" v-else>
        <div>                    
            <h5><img src="../../../assets/images/Shape.png" class="pr-1 pb-1 darkicon" alt="" />User Details</h5>
        </div>
      <div class="profile-wrapper" v-if="userProfile">
        <div class="profile-content">
          <div class="user-profile-image">
            <div class="profile-image">
              <div id="preview" class="profile-image cstm-cursor" >
              <div v-if="isImageLoaded" class="img-loader">             
                <div  class="lds-dual-ring-btn" id="loading"></div>
              </div>
              <img v-if="url" :src="url" @load="onImageLoad" /> 
              <img v-else src="../../../assets/images/avater.png" alt="" @load="onImageLoad" /> 
              </div>
            </div>
  
            <div class="change-profile-image justify-content-center">
              <form>
                <input
                  type="file"
                  id="vue-file-upload-input-profile-photo"
                  class="d-none"
                  accept="image/png, image/gif, image/jpeg"
                />
              </form>

            </div>
              <div class="btns text-center">
              <button class="custom-btn2 px-4 mr-0" @click="EditProfile()">
                Edit User Details
              </button>
            </div>
            <div class="btns text-center mt-2">
              <button v-if="userView.status == 2" @click="activeUser(userView)" class="custom-btn5 px-4" >
                Activate
              </button>
              <button v-else-if="userView.status == 1" @click="deActiveUser(userView)" class="custom-btn5 px-4" >
                Deactivate
              </button>
            </div>
          </div>
            
          <div class="user-profile-data">
            <div class="container-fluid">
                <h6>Profile</h6>
              <div class="row">
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">First Name</span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name1"
                      v-model="details.first_name"
                      placeholder="Enter First Name"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Last Name </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name2"
                      v-model="details.last_name"
                      placeholder="Enter Last Name"
                      autocomplete="off"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Title </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      v-model="details.title"
                      placeholder="Enter Title"
                      autocomplete="off"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Department </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="department"
                      v-model="details.department"
                      placeholder="Enter Department"
                      autocomplete="off"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Business Email </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name3"
                      v-model="details.email"
                      placeholder="Enter Business Email"
                      autocomplete="off"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Alternate Email </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name322"
                      v-model="details.alternate_email"
                      placeholder="Enter Alternate Email"
                      autocomplete="off"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">LinkedIn </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="linkedIn"
                      v-model="details.linkedIn"
                      placeholder="Enter LinkedIn Account"
                      autocomplete="off"
                      readonly
                    />
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Twitter </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="twitter"
                      v-model="details.twitter"
                      placeholder="Enter Twitter Account"
                      autocomplete="details.twitter"
                      readonly
                    />
                  </div>
                </div>

                <div class="col-lg-12 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Phone Number </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      v-model="details.phone"
                      placeholder="Enter Phone Number"
                      autocomplete="off"
                      readonly
                    />
                  </div>
                </div>
               <div class="col-12 px-0">
                <h6>Settings</h6>
               </div>

                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Security Question</span>
                      </div>
                    </label>
                    <v-select
                      v-model="details.question"
                      :options="questionList"
                      label="name"
                      :placeholder="questionPlaceholder"
                      @input="setSelected"
                      class="custom-select p-0"
                      v-bind:class="{ disabledInput: true }"
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Security Answer</span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control pl-3"
                      id="answer"
                      placeholder="Security Answer"
                      autocomplete="details.answer"
                      v-model="details.answer"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="profile-wrapper" v-if="updateProfile">
        <div class="profile-content">
          <div class="user-profile-image">
            <div class="profile-image">
              <div id="preview" class="profile-image cstm-cursor">
                <img src="../../../assets/images/penWhite.png" class="profile-icon-dark d-none" alt="" @click="openinput()">
                <img src="../../../assets/images/penGrey.png" class="profile-icon" alt="" @click="openinput()">
                
                <div v-if="isImageLoaded" class="img-loader">             
                  <div  class="lds-dual-ring-btn" id="loading"></div>
                </div>
                <img v-if="url" :src="url" @load="onImageLoad" /> 
                <img v-else src="../../../assets/images/avater.png" alt="" @load="onImageLoad" />
              </div>
            </div>
  
            <div
              class="change-profile-image justify-content-center"
              @click="openinput()"
            >
              <form>
                <input
                  type="file"
                  id="vue-file-upload-input-profile-photo"
                  @change="onFileChange"
                  class="d-none"
                  accept="image/png, image/gif, image/jpeg"
                />
              </form>
            </div>
            <div class="profile-btns">
              <button
                class="custom-btn2"
                @click="Update(0)"
                :disabled="isLoadingArray"
              >
                <div v-if="isLoadingArray" class="lds-dual-ring"></div>
                <span v-else> Save Changes</span>
              </button>
              <button class="custom-btn2" @click="cancelUpdate()">Cancel</button>
            </div>
          </div>
          <div class="user-profile-data">
            <div class="container-fluid">
                <h6>Profile</h6>
              <div class="row">
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >First Name <span class="astrick">*</span></span
                        >
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name1"
                      placeholder="Enter First Name"
                      autocomplete="details.first_name"
                      v-model.trim="$v.details.first_name.$model"
                      :class="{
                        'is-invalid': $v.details.first_name.$error,
                        'is-valid': !$v.details.first_name.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.first_name.required"
                        >First name is required</span
                      >
                      <span v-if="!$v.details.first_name.minLength"
                        >First name must have at least
                        {{ $v.details.first_name.$params.minLength.min }}</span
                      >
                      <span v-if="!$v.details.first_name.maxLength"
                        >First name must have at most
                        {{ $v.details.first_name.$params.maxLength.max }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Last Name <span class="astrick">*</span>
                        </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name2"
                      placeholder="Enter Last Name"
                      autocomplete="details.last_name"
                      v-model.trim="$v.details.last_name.$model"
                      :class="{
                        'is-invalid': $v.details.last_name.$error,
                        'is-valid': !$v.details.last_name.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.last_name.required"
                        >Last name is required</span
                      >
                      <span v-if="!$v.details.last_name.minLength"
                        >Last name must have at least
                        {{ $v.details.last_name.$params.minLength.min }}</span
                      >
                      <span v-if="!$v.details.last_name.maxLength"
                        >Last name must have at most
                        {{ $v.details.last_name.$params.maxLength.max }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Title <span class="astrick">*</span>
                        </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      placeholder="Enter Title"
                      autocomplete="details.title"
                      v-model.trim="$v.details.title.$model"
                      :class="{
                        'is-invalid': $v.details.title.$error,
                        'is-valid': !$v.details.title.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.title.required"
                        >Title is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Department <span class="astrick">*</span>
                        </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="department"
                      placeholder="Enter Department"
                      autocomplete="details.department"
                      v-model.trim="$v.details.department.$model"
                      :class="{
                        'is-invalid': $v.details.department.$error,
                        'is-valid': !$v.details.department.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.department.required"
                        >Department is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Business Email <span class="astrick">*</span>
                        </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name3"
                      placeholder="Enter Business Email"
                      autocomplete="details.email"
                      v-model.trim="$v.details.email.$model"
                      :class="{
                        'is-invalid': $v.details.email.$error,
                        'is-valid': !$v.details.email.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.email.required"
                        >Business email is required</span
                      >
                      <span v-if="!$v.details.email.email"
                        >Business email must be valid
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Alternate Email </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name32"
                      placeholder="Enter Alternate Email"
                      autocomplete="details.alternate_email"
                      v-model.trim="$v.details.alternate_email.$model"
                      :class="{
                        'is-invalid': $v.details.alternate_email.$error,
                        'is-valid': !$v.details.alternate_email.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.alternate_email.email"
                        >Alternate Email must be valid
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">LinkedIn </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="linkedIn"
                      placeholder="Enter LinkedIn Account"
                      autocomplete="details.linkedIn"
                      v-model="details.linkedIn"
                    />
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Twitter </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="twitter"
                      placeholder="Enter Twitter Account"
                      autocomplete="details.twitter"
                      v-model="details.twitter"
                    />
                  </div>
                </div>

                <div class="col-lg-12 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text">Phone Number </span>
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      placeholder="Enter Phone Number"
                      autocomplete="details.phone"
                      v-model="details.phone"
                    />
                  </div>
                </div>
                
                
                <div class="col-12 px-0">
                  <h6>Settings</h6>
                </div>
                <div class="col-lg-6 px-0 pr-lg-2">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Security Question <span class="astrick">*</span></span
                        >
                      </div>
                    </label>
                    <v-select
                      v-model.trim="$v.details.question.$model"
                      :options="questionList"
                      label="name"
                      :placeholder="questionPlaceholder"
                      @input="setSelected"
                      class="custom-select p-0"
                      :class="{
                        'is-invalid': $v.details.question.$error,
                        'is-valid': !$v.details.question.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.question.required"
                        >Question is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Security Answer <span class="astrick">*</span></span
                        >
                      </div>
                    </label>
                    <input
                      type="text"
                      class="form-control pl-3"
                      id="answer"
                      placeholder="Security Answer"
                      autocomplete="details.answer"
                      v-model.trim="$v.details.answer.$model"
                      :class="{
                        'is-invalid': $v.details.answer.$error,
                        'is-valid': !$v.details.answer.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.answer.required"
                        >Answer is required</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import axios from "axios";
  import {
    required,
    minLength,
    maxLength,
    email,
  } from "vuelidate/lib/validators";
  let questions = require("../../../assets/js/questions");
  export default {
    name: "adminUserView",
    data() {
      return {
        userView: {},
        isImageLoaded: true,
        userProfile: true,
        updateProfile: false,
        time: this.$time,
        API_URL: this.$userProfileStorageURL,
        url: null,
        isLoadingArray: false,
        profilePhoto: "",
        oldPicture: true,
        password: "",
        errorMessage: null,
        confirmPassword: "",
        message: "",
        details: {
          first_name: "",
          last_name: "",
          title: "",
          department: "",
          email: "",
          phone: "",
          linkedIn: "",
          twitter: "",
          question: "",
          answer: "",
          alternate_email: "",
          secret_answer_status: 1,
        },
        questionList: questions,
        questionPlaceholder: "Select security question",
        
      };
    },
    props:{
      loader: Boolean,
      getUser:{
        type: Function
      }
    },
    validations: {
  
      details: {
        first_name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
        last_name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
        title: {
          required,
        },
        department: {
          required,
        },
        question: {
          required,
        },
        answer: {
          required,
        },
        email: {
          required,
          email,
        },
        alternate_email: {
          email,
        },
      },
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },
    methods: {
      ...mapActions("auth", ["getUserData"]),
      onImageLoad() {
        this.isImageLoaded = false; 
      },
      EditProfile: function() {
        this.updateProfile = true;
        this.userProfile = false;
        this.profileData();
      },
      cancelUpdate: function() {
        this.isLoadingArray= false;
        this.userProfile = true;
        this.updateProfile = false;
        this.profileData();
      },
      onFileChange(e) {
        this.oldPicture = false;
        this.profilePhoto = e.target.files[0];
        this.url = URL.createObjectURL(this.profilePhoto);
      },
      openinput: function() {
        document.getElementById("vue-file-upload-input-profile-photo").click();
      },
      Update: function(index) {
        var formData = new FormData();
      
        if (this.details) {
          this.$v.details.$touch();
          if (this.$v.details.$pendding || this.$v.details.$error) return;
        }
        console.log(index)
        // this.$set(this.isLoadingArray, index, true);
        this.isLoadingArray = true;
        formData.append("user_id", this.userView.id);
        formData.append("first_name", this.details.first_name);
        formData.append("email", this.details.email);
        formData.append(
          "alternate_email",
          this.details.alternate_email ? this.details.alternate_email : ""
        );
        formData.append("last_name", this.details.last_name);
        formData.append("avatar", this.profilePhoto);
        formData.append("title", this.details.title);
        formData.append("department", this.details.department);
        formData.append(
          "phone_number",
          this.details.phone ? this.details.phone : ""
        );
        formData.append(
          "twitter",
          this.details.twitter ? this.details.twitter : ""
        );
        formData.append(
          "linkedIn",
          this.details.linkedIn ? this.details.linkedIn : ""
        );
        formData.append("secret_question", this.details.question);
        formData.append("secret_answer", this.details.answer);
        if (this.details.answer != "") {
          formData.append(
            "secret_answer_status",
            this.details.secret_answer_status
          );
        } else {
          formData.append("secret_answer_status", 0);
        }
        axios
          .post(process.env.VUE_APP_API_URL + "user/updateSingleUser", formData)
          .then((response) => {
            if (response.data.statusCode === 200) {
              this.$swal({
                title: "Your profile has been updated!",
                icon: "success",
                showCancelButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.isLoadingArray= false;
                  this.getUser();
                  this.userProfile = true;
                  this.updateProfile = false;
                }
              });
            } else {
              this.isLoadingArray = false;
              this.message = response.data.message;
              this.$swal({
                text: this.message,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              alert(error.response.data.message);
              this.isLoadingArray = false;
            }
          });
      },
      profileData() {
        this.$v.$reset();
          this.url = "";
          this.details.department = "";
          this.details.title = "";
          this.details.phone = "";
          this.details.linkedIn = "";
          this.details.twitter = "";
          this.details.alternate_email = "";
          this.details.answer = "";
          this.details.question = "";
        if (this.user) {
          this.details.first_name = this.userView.first_name;
          this.details.last_name = this.userView.last_name;
          this.url = this.userView.avatar;
          this.details.department = this.userView.department;
          this.details.title = this.userView.title;
          this.details.phone = this.userView.phone_number;
          this.details.linkedIn = this.userView.linkedIn;
          this.details.twitter = this.userView.twitter;
          this.details.email = this.userView.email;
          this.details.alternate_email = this.userView.alternate_email;
          if (this.userView.secret_answer == null) {
            this.details.answer = "";
          } else {
            this.details.answer = this.userView.secret_answer;
          }
          if (this.userView.secret_question == null) {
            this.details.question = "";
          } else {
            this.details.question = this.userView.secret_question;
          }
        }
      },
  
      setSelected(value) {
        this.details.question = value.name;
      },
      activeUser(userView){
        this.$swal({
          title: "Do you want to activate this agreement?",
          // text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Yes",
          showCancelButton: true,
        }).then((result) => {

          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("user_id", userView.id);
            formData.append("status", "1");
            
            axios
              .post(process.env.VUE_APP_API_URL + "user/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getUser();
                  this.$swal("Activated!", "This agreement has been activated.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      deActiveUser(userView){
        this.$swal({
          title: "Do you want to deactivate this agreement?",
          // text: "Please Verify.",
          icon: "info",
          confirmButtonText: "Yes",
          showCancelButton: true,
        }).then((result) => {

          if (result.isConfirmed) {
            var formData = new FormData();
            
            formData.append("user_id", userView.id);
            formData.append("status", "2");
            
            axios
              .post(process.env.VUE_APP_API_URL + "user/updateStatus", formData)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  this.getUser();
                  this.$swal("Deactivated!", "This agreement has been deactivated.", "success");
                }
              })
              .catch((error) => {
                if (error.response.status === 500) {
                  alert(error.response.data.message);
                }
              });
          }
        });
      },
      
    },
    mounted() {
      this.$root.$on("usersView", (data) => {
        this.userView = data;
        this.profileData();
      });
      this.profileData();
    },
  };
  </script>
  <style scoped>
   .lds-dual-ring-registry {
    position: absolute;
    z-index: 999;
    padding-top: 50% !important;
  }
  </style>