<template>
    <div class="app-page">
      <Navbar />
      <div class="main-wrapper">
        <LeftMenu v-if="Admin"/>
        <LeftMenu1 v-else />
        <div class="registry-wrapper">
          <div class="home-registry-wrapper">
              <adminUserView 
              :loader = "loading"
              :getUser="getUser"
              />
          </div>
        </div>
      </div>
    </div>
  </template>
  
    <script>
    import LeftMenu from "../../../components/leftmenu/LeftMenu.vue";
    import LeftMenu1 from "../../../components/Enterprise/leftmenu/LeftMenu.vue";    import Navbar from "../../../components/Enterprise/header/Navbar.vue";
    import adminUserView from "../../../components/admin/user/adminUserView.vue";
    import { mapGetters } from "vuex";
    import axios from "axios";
    
    export default {
      name: "usersView",
      data() {
      return {
        Admin: false,
        userView: {},
        userDATA: {},
        showloader: 0,
        fullPage: false,
        loading: true,
      };
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },
    methods:{
      getUser(){
        axios
          .get(process.env.VUE_APP_API_URL + `user/singleUser?user_id=${this.userDATA.id}`)
          .then((response) => {
            if (response.data.statusCode === 200) {
              // console.log(response.data.data)
                this.userView = response.data.data.rows[0];
                setTimeout(() => {
                  this.$root.$emit("usersView", this.userView);
                  this.showloader = 1;
                  this.loading= false;
            }, 500);
            }
          })
          
          .catch((error) => {
            if (error.response.status === 500) {
              alert(error.response.data.message);
            }
          });
  
      }
    },
    mounted() {
        
        this.$root.$on("dataForUsers", (data) => {
          this.userDATA = data
          this.getUser();
        });
        if(this.user.agreement != null ){
          this.userDATA = this.user.agreement
          this.getUser();
        }
        if (localStorage.getItem("permissions") == 1) {
          this.Admin = true;
        }
      },
    
      components: {
        Navbar,
        LeftMenu,
        LeftMenu1,
        adminUserView
        
      },
      
    };
    </script>
    <style  scoped>
    .lds-dual-ring-registry {
      position: absolute;
      z-index: 999;
      padding-top: 50% !important;
    }
     </style>